<template>
  <div class="page-container">
    <el-card>
      <el-row class="header-search-bar">
        <el-col :span="6">
          <el-input
            placeholder="开锁人"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getDataList('search')"
            @keyup.enter.native="getDataList('search')"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getDataList('search')"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form
            ref="form"
            :model="queryInfo"
            :inline="true"
            class="demo-form-inline"
          >
            <el-form-item style="margin-left: -20px" prop="startDate">
              <el-col :span="7">
                <el-date-picker
                  type="datetime"
                  size="mini"
                  placeholder="开始时间"
                  v-model="queryInfo.startTime"
                  style="width: 100%"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </el-col>
              <el-col class="line" style="text-align: center" :span="1">~</el-col>
              <el-col :span="7">
                <el-date-picker
                  type="datetime"
                  size="mini"
                  placeholder="结束时间"
                  v-model="queryInfo.endTime"
                  style="width: 100%"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </el-col>
              <el-col class="line" style="text-align: center;margin-left: 10px" :span="6">
                <el-select v-model="queryInfo.state" size="mini">
                  <el-option value="unLockSuccess" label="成功开锁">蓝牙成功开锁</el-option>
                  <el-option value="getDynamicPassword" label="获取动态密码">获取动态密码</el-option>
                  <el-option value="getBluetoothKey" label="获取蓝牙指令">获取蓝牙指令</el-option>
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-button @click="getDataList" style="margin-left: 10px" size="mini">筛选</el-button>
              </el-col>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table :data="dataList" style="width: 100%" border stripe>
        <el-table-column prop="createTime" label="开锁人" width="100">
          <template v-slot="scoped">
            <span v-if="scoped.row.realName!=null">{{ scoped.row.realName }}</span>
            <span v-if="scoped.row.stuName!=null">{{ scoped.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userType" label="开锁人类型" width="100">
          <template v-slot="scoped">
            <span v-if="scoped.row.userType==='3'">管理员/教师</span>
            <span v-if="scoped.row.userType==='2'">学生</span>
          </template>
        </el-table-column>
        <el-table-column prop="idNumber" label="身份证" width="250">
        </el-table-column>
        <el-table-column prop="lockName" label="锁名" width="200">
        </el-table-column>
        <el-table-column prop="featureCode" label="featureCode" width="250">
        </el-table-column>
        <el-table-column prop="createTime" label="开锁时间">
        </el-table-column>
        <el-table-column prop="state" label="开锁类型">
          <template v-slot="scoped">
            <el-tag type="success" v-if="scoped.row.state==='unLockSuccess'">蓝牙成功开锁</el-tag>
            <el-tag v-if="scoped.row.state==='getDynamicPassword'">获取动态密码</el-tag>
            <el-tag v-if="scoped.row.state==='getBluetoothKey'">获取蓝牙指令</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <base-pagination
        :total="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></base-pagination>
    </el-card>
  </div>
</template>

<script>

import { getSysUserLockRecordList } from '@/api/lock'

export default {
  name: 'list',
  data () {
    return {
      dataList: [],
      queryInfo: {
        keyword: null,
        startTime: null,
        endTime: null,
        state: null
      }
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getDataList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getDataList()
    },
    // 获取外出请假列表
    getDataList () {
      getSysUserLockRecordList(this.queryInfo).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      })
    }
  }
}
</script>

<style scoped>

</style>
